import React, { useEffect, useState, Fragment } from "react";
import Loader from "react-js-loader";
import './App.css';
import gif from './images/gif01.gif';
import contract from './contracts/ABI.json';
import { ethers } from 'ethers';
import Parse from 'html-react-parser';


//Contract Address
const contractAddress = "0xbCFf5CDd71aFF0610529A2dFa0F702FD8D4242ad";
//Contract ABI - Import from contract folders.
const abi = contract.abi;


function App() { 

  //Keep track of the user’s wallet address.
  const [currentAccount, setCurrentAccount] = useState(null);    
  //To give Feedback of each process
  const [feedback, setFeedback] = useState(`Click button Above to Connect to wallet.`);
  //To monitor the process of claiming NFT
  const [claimingNft, setClaimingNft] = useState(false);
  //this is to set amount to be minted
  const [mintAmount, setMintAmount] = useState(1);
  const { ethereum } = window;
  
  // Check if the Metamask wallet exists. useEffect hook that checks Metamask’s existence.
  const checkWalletIsConnected = async () => { 
    // const { ethereum } = window;
  
    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      alert("Please install Metamask!");
      return;
    } else {
      console.log("wallet exists! We're ready to go!");
      const accounts = await ethereum.request({ method: 'eth_accounts'});  
      //to check the network ID
      const networkId = await ethereum.request({ method: 'net_version'});  

      if (accounts.lenght !==0) {
        if(networkId !== "137"){
          setCurrentAccount(null);
          setFeedback(`Please Change to Polygon Network`);
          console.log("wrong network");
        }else{
          const account = accounts[0];
          console.log("Found an authorized account: ", account);
          setCurrentAccount(account);
          setFeedback(`Select the number of houses you want to mint.`); 
        }    
      } else {
        console.log("No authorized account found")
      }
    }     
  };

  //Checks if Metamask is installed. If not, the displays a pop-up asking to install Metamask.
  const connectWalletHandler = async () => { 
    // const { ethereum } = window;
    
    if (!ethereum) {
      alert("Please install Metamask!");
    }

    //Requests Metamask for the user’s wallet addresses. Once connected, takes the first wallet address available and sets it as the value of the currentAccount variable.
    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const networkId = await ethereum.request({ method: 'net_version'}); 

      if(networkId !== "137"){
        setCurrentAccount(null);
        setFeedback(`Still on the Wrong Network, Please Change to Ethereum Network`); 
        console.log("wrong network");
      }else{
        console.log("Found an account! Address: ", accounts[0]);        
        setCurrentAccount(accounts[0]);
        setFeedback(`Click button Above to purchase your NFT.`); 
      }    
    } catch (err) {
      console.log(err)
    }  
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const mintNftHandler = async () => { 
    try {
      // const { ethereum } = window;

      if (ethereum) {
        //connect to smart contract

        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Initialize payment");
        
        let nftTxn = await nftContract.mintNFTs(mintAmount, 
        { 
          // gasPrice: String(20e9),
          gasLimit: String(285000*mintAmount),
          value: ethers.utils.parseEther(String(mintAmount*15))
        });
        console.log("Mining... please wait"); 
        setFeedback(`You are almost a house owner... Please wait.`);        
        setClaimingNft(true);        
        
        await nftTxn.wait();
        console.log(nftTxn);

        setFeedback(
          `<p> You successfully minted your house(s). See transaction: <a target="_blank" href="https://polygonscan.com/tx/${nftTxn.hash}">HERE</a></p>
           <p> Check OpeanSea for your <a target="_blank" href="https://opensea.io/collection/ks-real-estate">NFT</a></p>`
          ); 
        setClaimingNft(false); 
        

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      setFeedback("Sorry, something went wrong please try again later.");
      setClaimingNft(false);
      console.log(err);
    }
  };

  const connectWalletButton = () => {    
    return (        
      <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>                         
        Connect your wallet        
      </button>     
    )
  };

  const mintNftButton = () => {
    return (
      <button onClick={mintNftHandler} disabled={claimingNft ? 1 : 0} className='cta-button mint-nft-button'>        
        {claimingNft ? <Loader type="bubble-loop" bgColor={"#FFFFFF"} title={"bubble-loop"} color={'#FFFFFF'} size={70} /> : "Mint your house(s)"}
      </button>
    )
  };

  const soonButton =() => {
    return (
      <button>
        Coming Soon!
      </button>
    )
  };

  useEffect(() => {
    checkWalletIsConnected();
    ethereum.on('accountsChanged', function (accounts) {
      console.log("address as been changed");
      checkWalletIsConnected();
    });
    ethereum.on('chainChanged', function (networkId) {
      console.log("Network ID Changed");
      checkWalletIsConnected();
    });
  }, [])



  return (   
    <div>     

      <div>        
          <img src={gif} alt="Which Billy are you?" height="350" widht="350" className="GIF"></img>          
      </div>      
            
      <div>              
        {currentAccount ? mintNftButton() : connectWalletButton()}     
      </div>
      
       {/* mint amount button */}
       <div className="mint-button-font">
        <button type="button" 
          disabled={claimingNft ? 1 : 0}  
          onClick={(e) => {
            e.preventDefault();
            decrementMintAmount();
            }}
            className="mint-button">
            -
        </button>
        {mintAmount}
        <button type="button" 
          disabled={claimingNft ? 1 : 0}  
          onClick={(e) => {
            e.preventDefault();
            incrementMintAmount();
            }}
          className="mint-button">
            +
        </button>
      </div>

      <div className="feedback">
      {Parse(feedback)}
      </div>
      
    </div>    
  )    

}
export default App;